body {
  margin: 0;
  padding: 0;
  font-family: 
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
.MuiTypography-h5 {
  font-family:
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}
.breadcrumb{
  display:flex ;
  align-items: flex-end;
  margin:1rem;
  padding: 0.3rem;
  cursor: pointer;
}
.breadcrumb>div{
  margin:0.2rem;
  color: #2B2865;
  font-weight: 500;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.centerHeading{
  text-align: center;
  /* border: 1px solid rgb(141, 137, 137); */
  border-radius: 10px;
  color: aliceblue;
  /* background: linear-gradient(45deg, #22A4DC 30%, #2B2663 90%);  */
  /* background-color:linear-gradient(45deg, #2196F3 30%, #21CBF3 90%); */
  box-shadow: 0 3px 5px 2px rgba(33, 203, 243, .3);
  padding: 0.1rem;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  word-spacing: 20px;
  font-size: larger;
  background: linear-gradient(-45deg, #2196F3, #2B2663, #23a6d5, #2B2663);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;

}
@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}
/* .MuiDialogContent-root{
  background: linear-gradient(-45deg, rgb(28, 185, 203), rgb(209, 37, 214), rgb(247, 199, 9), #2B2663);
    background-size: 400% 400%;
    animation: gradientForm 15s ease infinite;
}
@keyframes gradientForm {
  0% {
    background-position: 0% 50%;
}
50% {
    background-position: 100% 50%;
}
100% {
    background-position: 0% 50%;
}
} */
.textSize{
  font-size:16Px;
  text-align: center;
  font-weight: bolder;
 /*background-color: #072452;
 color: white;*/
 /* margin: 2rem 0rem 2rem 0rems */
}
.Text-Gradient-Color{
  background: -webkit-linear-gradient(#51E3AC, #1FB4AB);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #F2F2F2;
  margin-top: 2.5rem;

}
.card-container>div{
  margin: 10px;
  /* border: 1px solid; */
  /* padding: 5px; */
  box-shadow: 3px 3px 3px 3px  #1d1c1c;
  border-radius: 5px;
  width: 200px;
}
.card-container>div:hover{
  background-color:rgb(17, 17, 17);
  transition: 0.3s;
}

.MuiCardMedia-img	{
  /* width: 50px; */
  height: 190px;
  /* object-fit: scale-down; */
 /* margin-top: 2rem; */
 padding: 15px;
}

.chart1BMAIN{
  display: flex;
  justify-content: space-around;
}

.Card{
  /*width: 200px;*/
  height:80px ;
  border: 4px #072452 solid;
  /* padding: 3px; */
  box-shadow: 5px 5px 5px 5px  #777676;
  border-radius: 10px;
  
  

 

}
/* /CHARTS STYLING/ */
.custom-tooltip {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid #c3c3c3;
  border-radius: 10px;}
  .custom-tooltip>span {
    font-weight: bold;
  }

  .Charts{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
   .TimeSelect{
  display: block;
  margin: 50px;
  padding: 50px;

}
.TimeSelect> div{
margin-top: 10px;
}
  /* Chart1b Guage */
  .container {
    display: flex;
    /*flex-wrap: wrap;*/
    justify-content: space-between;
    margin-top: .5rem;
  }


  
  .head{
    margin-left: 65px;
    
  }
  .container>div{
    /* height: 200px;
    width: 280px; */
   padding: 5px;
  }
  .Cards-1b{
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
      
  }
  .Cards-1b>div{
    /* margin: 2px; */
    padding: 2px;
    /* border: 3px solid #2E3B55;   */
    
    
  }
  .LossChart{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    
    
  }


/* DEFECTS HANDLING/ */
.SelectPlant{
  display: flex;
 flex-direction: row;
 justify-content: flex-start;
 flex-wrap: wrap;
 /* align-items: center; */
}
.SelectPlant>div{
  margin-left: 5px;
  padding-left: 15px;
  /* justify-content: space-around; */
}

 .dhForm{
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   flex-direction: column;
 }
 .dhForm>div{
   width: 80%;
   /* margin:0.5rem; */
   margin:0.25rem;

   
 }
 .dhForm>div>input{
  width: 100%;
  height: 100%;
  /* padding: 1rem; */
  font-size: 16px;
  /* border-radius: 1rem; */
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.3) 30%, rgba(255, 255, 255, 0.2) 90%);
  cursor:text;
  color: black;
}
.dhForm>div>input:focus{
  border-color:black;
  box-shadow:0 0 8px 0 black;
}
.dhForm>div>input::placeholder{
  color: black;
}
 
.dhForm>div>select {
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 100%;
  height: 3em;
  padding: 0 4em 0 1em;
  background: url('./component/icon/caret-down-solid.svg')
      no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, rgba(255, 255, 255, 0.3) 3em,rgba(255, 255, 255, 0.2) 3em);
    background-size: 12px;
  color: black;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* <option> colors */
}
.dhForm>div>select>option {
  color: inherit;
  background-color: #33a1d8;
}
/* Remove focus outline */
.dhForm>div>select:focus {
  outline: none;
}
/* Remove IE arrow */
.dhForm>div>select::-ms-expand {
  display: none;
}
 .dhForm>div>textarea{
  width: 100%;
  height: 100%;
  /* padding: 1rem; */
  padding: 5px;
  border-radius: 5px;
  font-size: 16px;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.3) 30%, rgba(255, 255, 255, 0.2) 90%);
  cursor:text;
  color: black;
  /* white-space: normal;
    text-align: justify;
    -moz-text-align-last: center;
    text-align-last: center; */
}
.dhForm>div>textarea::placeholder{
  color: black;
  /* margin-left: 5px !important; */
}
 

.DHContainer{
display: flex;
flex-direction: column;
flex-wrap: wrap;
}
.test-TwoBtn{
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.OHContainer{
  /* display: flex; */
  display: flex;
  flex-direction: column;
  height: 100vh;

}
.OHContainer>div{
  margin-top: 20px;
}




/* DHGRAPH */
.DHGCards{
display: flex;
justify-content: space-between;
/* align-items: center; */
flex-wrap: wrap;
}
.DHGCards>div{
  width: 10rem;
  padding: 5px;
}
/* .graphContainer{  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.graphContainer>div{
  margin: 1px;
}
.graphContainer>div:hover{
  background-color: thistle;
  transition: 2s;
} */
/*mehak edit*/
.filter{
display:flex;
flex-direction: row;
flex-wrap: nowrap;
flex-grow: 0.2;
/*height: 11rem;*/
/*align-items: space-between;
justify-content: space-between;*/

}
.datefilter{
  
  font-weight:"bold";
  justify-content:"space-evenly";
  /*flex-grow: 1rem;*/
  display:"flex";
  flex-direction:"column";
  
  
  /*flex-wrap: nowrap;*/

}

/* machine stops */
.machineSelect{
  display: flex;
  justify-content: flex-start;
  margin: 20px;
}


/* margin top */
.formUser>div{
  margin-top: 10px;
}

/* /mehak  edit for signin & sign up/ */
.textfield_label{
  color: #27d5e6 !important;
}
.textfield_label:focus{
  box-shadow: 0 0 5px rgba(81, 203, 238, 1) !important;
  padding: 3px 0px 3px 3px !important;
  margin: 5px 1px 3px 0px !important;
  border: 1px solid rgba(81, 203, 238, 1) !important;
}
.textfield_insidetext{
  color: white !important;
}

/* skeleton */
.skeletonAdjust{
  height: 50vh;
  margin-top: 10%;
  line-height: 2;
}

/* app bar text logo */
.logoText{
  text-decoration: none;
  transition: 0.5s ease-in-out;
   color: white
}
.logoText:hover{
  text-decoration: "none";
  color: orangered;
  transition: 0.7s;
}

.logoText1{
  text-decoration: none;
   color: white;
   transition: 0.4s ease-in-out;
   border: 1px solid orange;
   border-radius: 5px;
   padding: 3px;
}
.logoText1:hover{
  text-decoration: "none";
  border: 1px solid orange;
  padding: 6px;
  color: orangered;
  transition: 0.4s ;
  border-radius: 10px;
}

/* input fields */
input{
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #555;
  border-radius: 4px;
  outline: none;
  
  

}

.Filters{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.dh-form-for-mobile-resp{
  height: 100%;
}

/* Media queires */

@media only screen and (max-width: 700px) {
  .dhForm{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    
  }
  .dhform .div1to6.div7t12>div{
    width: 100%;}
 }
.userCreationForm>div{
  margin-top: 5px;
  padding: 5px;
  
}


/* Media queires */
@media only screen and (max-height: 580px){
.dh-form-for-mobile-resp{
  height: auto;
}
}


/* Select Search CSS */
.select-search {
  width: 100%;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2FCC8B;
  color: #fff;
  width: 97%;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
  width: 97%;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}


/* Dashboard || department */
/* .MuiDialog-paperFullScreen{
  height: auto !important;  
} */

.typography{
  color: "black";
  font-weight: "bolder"; 
  font-family: 'ariel'
}
.dialog-btn{
  background: linear-gradient(45deg, #2196F3 30%, #21CBF3 90%);
  color: white !important;
  padding: 8px 8px;
  font-size: 12px;
  font-weight: bold
}
.close-btn-abc{
  background: linear-gradient(45deg, #FE6B8B 30%, red 90%) !important
  
}
.btn-primary{
  width: 100% !important;
}

@media only screen and (max-width: 700px) {
  .icon-size{
  margin-left: auto;
  margin-right: auto;
    height: auto !important;
    width: 5rem !important;
  }
    .Heading{
    margin-top: 3rem;
    text-align: center;
  }  
  .Card-container>div{
    margin: 0.2rem
  }
  .Pie-chart{
    width: 25rem !important;
    margin-left: auto;
  margin-right: auto;
  }
  .Bar-Chart>div>div{
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }
  .centerHeading {
    margin-top: 2rem;
  }
  .DHContainer{
    margin-top: 2rem;
  }
  .Text-Gradient-Color{
    font-size: 32px !important;
  }
  .dialog-mobile-view{
      /* display: flex;
      justify-content: center;
      align-items: center; */
    font-size: 35px !important;
  }
  .typography{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px !important;
  }
  .mobile-view-form{
    margin-top: 3rem;
  }
  .mobile-view-text-field{
    height: 10vh !important;
    margin-top: 1rem;
  }
  .mobile-view-btn-container{
    display: flex;
    flex-direction: column;
  }
  .dialog-btn{
    margin-bottom: 1rem !important;
    font-size: 20px !important  ;
  }
  .close-btn-abc{
    background: linear-gradient(45deg, #FE6B8B 30%, red 90%) !important
  }
}

@media only screen and (max-width: 764px) {
  .icon-size{
    margin-left: auto;
    margin-right: auto;
      height: auto !important;
      width: 5rem !important;
    }
}


@media only screen and (max-width: 992px) {
  .navigation-view{
    max-width: 155px !important;
  }
}
@media only screen and (max-width: 350px) {
  .resp-view{
    min-width: 320px !important;
  }
  .resp-navbar{
    display: flex !important;
    /* flex-direction: row !important; */
  }
  .resp-child-element{
    max-width: 50%;
      /* width: 50% !important; */

    /* display: flex !important; */
    /* flex-direction: row !important; */
  }

}

@media only screen and (max-width: 570px) {
  .navigation-view{
    display: none !important;
    }
    .resp-navbar{
      display: flex !important;
      flex-direction: row !important;
    }
    .resp-child-element{
      /* max-width: 50%; */
      width: 50% !important;
      margin: 0%  !important;
      padding: 0%  !important;
    }
    .resp-logo-heading{
      display: none;
    }
    .resp-logo-img{
      display: block !important;
      margin-left: 0.5rem;
    }

}

/* display none for drawer for mobile screen */

@media only screen and (max-width: 500px) {
  .navigation-view{
    display: none !important;
    }
    .resp-font-size{
      font-size: 8px !important;
    }
    .MuiIconButton-root{
      padding: 0% !important;
    }
    .drawer{
      display: none !important;
    }
    .menu-icon{
      display: none !important;
    }
    .typography{
      font-size: 24px !important;
    }
}


.resp-logo-img{
  display: none;
}
.word-spacing{
  word-spacing: 5px;
}

text#SvgjsText5285{
  margin: 10px;
  font-size: 24px !important;
}

.Toastify__toast-theme--dark {
  background-color: #000 !important;
  color: #fff !important;
}



.back-ground-color {
  background: #a1ffce; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #faffd1,
    #a1ffce
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #faffd1,
    #a1ffce
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.col-3 {
  float: left;
  width: 27.33%;
  margin: 10px 3%;
  position: relative;
}
input[type="text"] {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}
input[type="password"] {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

.effect-19,
.effect-20,
.effect-21 {
  border: 1px solid #ccc;
  padding: 7px 14px;
  transition: 0.4s;
  background: transparent;
}

.effect-19 ~ .focus-border:before,
.effect-19 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 50%;
  width: 0;
  height: 2px;
  /* background-color: black; */
  border-radius: 5px;
  background-color: rgb(124,204,191);
  transition: 0.4s;
}
.effect-19 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  border-radius: 5px;

}
.effect-19 ~ .focus-border i:before,
.effect-19 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: 0;
  /* background-color: #117fec; */
  border-radius: 5px;

  background-color: rgb(124,204,191);
  transition: 0.6s;
}
.effect-19 ~ .focus-border i:after {
  left: auto;
  right: 0;
}
.effect-19:focus ~ .focus-border:before,
.effect-19:focus ~ .focus-border:after,
.has-content.effect-19 ~ .focus-border:before,
.has-content.effect-19 ~ .focus-border:after {
  left: 0;
  width: 100%;
  transition: 0.4s;
  border-radius: 5px;

}
.effect-19:focus ~ .focus-border i:before,
.effect-19:focus ~ .focus-border i:after,
.has-content.effect-19 ~ .focus-border i:before,
.has-content.effect-19 ~ .focus-border i:after {
  top: -1px;
  height: 100%;
  transition: 0.6s;
}
.effect-19 ~ label {
  position: absolute;
  left: 14px;
  width: 100%;
  top: 10px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-19:focus ~ label,
.has-content.effect-19 ~ label {
  top: -18px;
  left: 0;
  font-size: 12px;
  /* color: black; */
  color: rgb(124,204,191);
  transition: 0.3s;
}


.action-input{
  padding: 8px 15px;
}
